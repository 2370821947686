<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="12" lg="12" cols="12">
          <b-card-actions
              ref="first-card"
              id="kasa-header"
              @refresh="refreshStop('first-card')"
          >
            <b-row class="mt-1 unPrint">
              <b-col :xl="baslangic && bitis ? 4 : 6" :lg="baslangic && bitis ? 4 : 6" :md="baslangic && bitis ? 4 : 6"
                     xs="12" class="justify-content-start d-flex"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="secondary"
                    class="mr-50"
                    @click="$router.go(-1)"
                    size="sm"
                >
                  <feather-icon
                      icon="ChevronLeftIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Geri Dön</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    class="mr-50"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgPrimary+'!important', border:'none'}"
                    size="sm"
                >
                  <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-50"/>
                  <span class="align-middle">Excel'e Aktar</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="info"
                    @click="print"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important', border:'none'}"
                    size="sm"
                    class="mr-50"
                >
                  <font-awesome-icon icon="fa-solid fa-print" class="mr-50"/>
                  <span class="align-middle">Yazdır</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="info"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important', border:'none'}"
                    size="sm"
                    v-b-modal.kasa-devir
                >
                  <font-awesome-icon icon="fa-solid fa-print" class="mr-50"/>
                  <span class="align-middle">Devir Girişi</span>
                </b-button>
              </b-col>
              <b-col v-if="baslangic && bitis" xl="4" lg="4" md="4" xs="12" class="justify-content-end d-flex">
                <h3 class="mx-auto tarih">
                  {{ date }}
                </h3>
              </b-col>
              <b-col :xl="baslangic && bitis ? 4 : 6" :lg="baslangic && bitis ? 4 : 6" :md="baslangic && bitis ? 4 : 6"
                     xs="12" class="justify-content-end d-flex"
                     :class="width < 768 ? 'mt-2':''"
              >
                <b-input-group class="float-right justify-content-end mr-50">
                  <b-input-group-prepend>
                    <flat-pickr v-model="baslangic" class="form-control"/>
                  </b-input-group-prepend>
                  <b-button
                      variant="outline-secondary"
                      disabled
                      class="date-border-color"
                  >
                    <feather-icon icon="CalendarIcon"/>
                  </b-button>
                  <b-input-group-append>
                    <flat-pickr v-model="bitis" class="form-control"/>
                  </b-input-group-append>
                </b-input-group>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="reset"
                >
                  Sıfırla
                </b-button>
              </b-col>
            </b-row>
          </b-card-actions>
          <b-card-actions
              ref="second-card"
              id="kasa-header"
              @refresh="refreshStop('second-card')"
          >
            <b-row class="mt-2">
              <b-col class="media-responsive cursor-pointer">
                <b-media no-body @click="$router.push({name: 'satislar'})">
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        variant="light-success"
                        class="pull-up"
                    >
                      <font-awesome-icon icon="fa-solid fa-file-invoice" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        Number(kasa.satisFaturalari)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Satış Faturaları
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="media-responsive cursor-pointer">
                <b-media no-body @click="$router.push({name: 'alislar'})">
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        variant="light-info"
                        class="pull-up"
                    >
                      <font-awesome-icon icon="fa-solid fa-receipt" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        Number(kasa.alisFaturalari)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Alış Faturaları
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="media-responsive">
                <b-media no-body id="cari-media">
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        class="pull-up"
                        variant="light-success"
                    >
                      <font-awesome-icon icon="fa-solid fa-plus" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        Number(kasa.tahsilatlar)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Tahsilatlar
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="media-responsive">
                <b-media no-body id="cari-media">
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        class="pull-up"
                        variant="light-danger"
                    >
                      <font-awesome-icon icon="fa-solid fa-minus" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        Number(kasa.odemeler)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Ödemeler
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="media-responsive">
                <b-media no-body id="cari-media">
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        class="pull-up"
                        variant="light-success"
                    >
                      <font-awesome-icon icon="fa-solid fa-plus" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        Number(kasa.bekleyenFaturaTahsilatlari)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Bekleyen Tahsilat
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="media-responsive">
                <b-media no-body id="cari-media">
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        class="pull-up"
                        variant="light-danger"
                    >
                      <font-awesome-icon icon="fa-solid fa-minus" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        Number(kasa.bekleyenFaturaOdemeleri)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Bekleyen Ödemeler
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-actions>
          <b-card-actions
              ref="third-card"
              id="kasa-header"
              @refresh="refreshStop('third-card')"
          >
            <h4 class="mt-2">Yıllık Kasa Raporu</h4>
            <vue-apex-charts type="bar" height="350" :options="chartOptions" :series="series" class="mt-2"/>
          </b-card-actions>
          <b-card-actions
              ref="fourth-card"
              id="kasa-header"
              @refresh="refreshStop('fourth-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Kasa Özeti</h4>
                </b-col>
              </div>
            </b-row>
            <hr/>
            <b-tabs class="mt-3">
              <b-tab title="Kasa Özeti" active @click="gelirListesiGet">
                <div class="d-flex justify-content-between flex-wrap">
                  <b-col lg="6" md="6" sm="12" cols="12">
                    <b-form-group class="mr-1 mb-md-0 unPrint per-page-input" size="sm">
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="thirdPerPage"
                            :options="pageOptions"
                            @change="thirdPerPageChange($event)"
                            size="sm"
                        >
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="12" cols="12" class="justify-content-end d-flex">
                    <b-input-group size="sm" class="search-bar">
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="search-input mb-0"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input id="filterInput" v-model="thirdFilter" type="search"/>
                    </b-input-group>
                  </b-col>
                </div>
                <hr/>
                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative kasa-table mt-1"
                    :per-page="thirdPerPage"
                    :fields="fields"
                    :items="ozetListesi"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                  <template #cell(hareket)="data">
                    <b-badge :variant="data.item.hareket == 1 ? 'success':'danger'" id="firma-unvan-badge"
                             class="align-top"
                    >
                      {{ data.item.hareket == 1 ? 'Tahsilat' : ' Ödeme ' }}
                    </b-badge>
                    <p id="firma-unvan-yetkili">{{ data.item.adi }}</p>
                  </template>
                  <template #cell(user)="data">
                    <strong>
                      {{ data.item.user }}
                    </strong>
                  </template>
                  <template #cell(islemNotu)="data">
                    <strong>
                      {{ data.item.islemNotu }}
                    </strong>
                  </template>
                  <template #cell(kayitTarih)="data">
                    {{ data.item.kayitTarih.slice(0, 16) }}
                  </template>
                  <template #cell(tutar)="data">
                    <strong class="ml-50">
                      {{
                        Number(data.item.tutar)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }} TL
                    </strong>
                  </template>
                  <template #cell(kasaBakiye)="data">
                    <strong class="ml-50">
                      {{
                        Number(data.item.kasaBakiye)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }} TL
                    </strong>
                  </template>
                  <template #cell(kasaID)="data">
                    <b-button
                        class="btn-icon rounded-circle trash-icon pull-up mr-50"
                        size="sm"
                        v-if="$store.getters.getRole == 1"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                        @click="devirSil(data.item.kasaID)"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </template>
                </b-table>
                <hr/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ thirdTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="thirdCurrentPage"
                        @change="thirdPageChange($event)"
                        :total-rows="thirdTotalRows"
                        :per-page="thirdPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Gelir Listesi" @click="gelirListesiGet">
                <div class="d-flex justify-content-between flex-wrap">
                  <b-col lg="6" md="6" sm="12" cols="12">
                    <b-form-group class="mr-1 mb-md-0 unPrint per-page-input" size="sm">
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="firstPerPage"
                            :options="pageOptions"
                            @change="firstPerPageChange($event)"
                            size="sm"
                        >
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="12" cols="12">
                    <b-input-group size="sm" class="search-bar float-right">
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="search-input mb-0"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input id="filterInput" v-model="firstFilter" type="search"/>
                    </b-input-group>
                  </b-col>
                </div>
                <hr/>
                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative kasa-table mt-1"
                    :per-page="firstPerPage"
                    :fields="fields"
                    :items="gelirListesi"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                  <template #cell(hareket)="data">
                    <b-badge :variant="data.item.hareket == 1 ? 'success':'danger'" id="firma-unvan-badge"
                             class="align-top"
                    >
                      {{ data.item.hareket == 1 ? 'Tahsilat' : ' Ödeme ' }}
                    </b-badge>
                    <p id="firma-unvan-yetkili">{{ data.item.adi }}</p>
                  </template>
                  <template #cell(user)="data">
                    <strong>
                      {{ data.item.user }}
                    </strong>
                  </template>
                  <template #cell(islemNotu)="data">
                    <strong>
                      {{ data.item.islemNotu }}
                    </strong>
                  </template>
                  <template #cell(kayitTarih)="data">
                    {{ data.item.kayitTarih.slice(0, 16) }}
                  </template>
                  <template #cell(tutar)="data">
                    <strong class="ml-50">
                      {{
                        Number(data.item.tutar)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }} TL
                    </strong>
                  </template>
                  <template #cell(kasaBakiye)="data">
                    <strong class="ml-50">
                      {{
                        Number(data.item.kasaBakiye)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }} TL
                    </strong>
                  </template>
                  <template #cell(kasaID)="data">
                    <b-button
                        class="btn-icon rounded-circle trash-icon pull-up mr-50"
                        size="sm"
                        v-if="$store.getters.getRole == 1"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                        @click="devirSil(data.item.kasaID)"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </template>
                </b-table>
                <hr/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ firstTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="firstCurrentPage"
                        @change="firstPageChange($event)"
                        :total-rows="firstTotalRows"
                        :per-page="firstPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab
                  title="Gider Listesi"
                  @click="giderListesiGet"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <b-col lg="6" md="6" sm="12" cols="12">
                    <b-form-group class="mr-1 mb-md-0 unPrint per-page-input" size="sm">
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="secondPerPage"
                            :options="pageOptions"
                            @change="secondPerPageChange($event)"
                            size="sm"
                        >
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="12" cols="12">
                    <b-input-group size="sm" class="search-bar float-right">
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="search-input mb-0"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input id="filterInput" v-model="secondFilter" type="search"/>
                    </b-input-group>
                  </b-col>
                </div>
                <hr/>
                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative kasa-table mt-1"
                    :per-page="secondPerPage"
                    :fields="fields"
                    :items="giderListesi"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                  <template #cell(hareket)="data">
                    <b-badge :variant="data.item.hareket == 1 ? 'success':'danger'" id="firma-unvan-badge"
                             class="align-top"
                    >
                      {{ data.item.hareket == 1 ? 'Tahsilat' : ' Ödeme ' }}
                    </b-badge>
                    <p id="firma-unvan-yetkili">{{ data.item.adi }}</p>
                  </template>
                  <template #cell(user)="data">
                    <strong>
                      {{ data.item.user }}
                    </strong>
                  </template>
                  <template #cell(islemNotu)="data">
                    <strong>
                      {{ data.item.islemNotu }}
                    </strong>
                  </template>
                  <template #cell(kayitTarih)="data">
                    {{ data.item.kayitTarih.slice(0, 16) }}
                  </template>
                  <template #cell(tutar)="data">
                    <strong class="ml-50">
                      {{
                        Number(data.item.tutar)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }} TL
                    </strong>
                  </template>
                  <template #cell(kasaBakiye)="data">
                    <strong class="ml-50">
                      {{
                        Number(data.item.kasaBakiye)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }} TL
                    </strong>
                  </template>
                  <template #cell(kasaID)="data">
                    <b-button
                        class="btn-icon rounded-circle trash-icon pull-up mr-50"
                        size="sm"
                        v-if="$store.getters.getRole == 1"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                        @click="devirSil(data.item.kasaID)"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </template>
                </b-table>
                <hr/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ secondTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="secondCurrentPage"
                        @change="secondPageChange($event)"
                        :total-rows="secondTotalRows"
                        :per-page="secondPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="kasa-devir"
        modal-class="modal-primary"
        centered
        title="Devir İşlemi"
        @ok="devirGirisi"
        @hidden="devirReset"
        :ok-disabled="devirKayitBtn"
        hide-footer
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Hareket Türü"
              label-for="basicInput"
          >
            <model-list-select
                class=""
                :list="devir.options"
                option-value="key"
                option-text="text"
                v-model="devir.tur"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Tutar"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="devir.tutar"
                autocomplete="off"
                :step="priceStep"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="İşlem Notu"
              label-for="basicInput"
          >
            <b-form-textarea
                id="textarea-default"
                rows="3"
                v-model="devir.not"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" class="text-right">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-50"
              @click="devirReset"
          >
            Vazgeç
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :style="{backgroundColor:$store.getters.COLORS_GET.bgSuceess+'!important'}"
              @click="devirGirisi"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BTab,
  BTabs,
  BFormTextarea,
  BCard,
  BCardText,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BMedia,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import 'vue-search-select/dist/VueSearchSelect.css'
import { useWindowSize } from '@vueuse/core'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BTab,
    BTabs,
    BFormTextarea,
    ModelListSelect,
    VueApexCharts,
    flatPickr,
    BCard,
    BCardText,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BMedia,
    BContainer,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      devirKayitBtn: false,
      devir: {
        not: '',
        tutar: '',
        tur: {
          key: 1,
          text: 'Kasa Girişi',
        },
        options: [
          {
            key: 1,
            text: 'Kasa Girişi',
          },
          {
            key: 2,
            text: 'Kasa Çıkışı',
          },
        ],
      },
      circleSeries: [
        {
          name: 'Visit source',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: 335,
              name: 'Point One',
            },
            {
              value: 310,
              name: 'Point Two',
            },
            {
              value: 234,
              name: 'Point Three',
            },
            {
              value: 435,
              name: 'Point Four',
            },
          ],
        },
      ],
      baslangic: '',
      bitis: '',
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            endingShape: 'rounded',
          },
        },
        colors: ['#408E91', '#FFBF9B', '#E96479'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return `${val} ₺`
            },
          },
        },
      },
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      firstPerPage: 10,
      firstToplamKayit: 1,
      firstTotalRows: 1,
      firstCurrentPage: 1,
      firstFilter: null,
      secondPerPage: 10,
      secondToplamKayit: 1,
      secondTotalRows: 1,
      secondCurrentPage: 1,
      secondFilter: null,
      thirdPerPage: 10,
      thirdToplamKayit: 1,
      thirdTotalRows: 1,
      thirdCurrentPage: 1,
      thirdFilter: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      fields: [
        {
          key: 'hareket',
          label: 'İşlem Türü',
          sortable: true,
          thStyle: {
            width: '6%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
        },
        {
          key: 'user',
          label: 'İşlem Sahibi',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '30%' },
        },
        {
          key: 'islemNotu',
          label: 'İşlem Notu',
          sortable: true,
          thStyle: {
            width: '40%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '30%' },
        },
        {
          key: 'kayitTarih',
          label: 'İşlem Tarihi',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'tutar',
          label: 'İşlem Tutarı',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
        },
        {
          key: 'kasaBakiye',
          label: 'Bakiye',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
          tdClass: ['text-right'],
          thClass: ['text-right'],
        },
        {
          key: 'kasaID',
          label: '',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
          tdClass: ['text-right'],
          thClass: ['text-right'],
        },
      ],
    }
  },
  computed: {
    date() {
      if (this.baslangic && this.bitis) {
        const firstGun = this.baslangic.slice(8, 10)
        const firstAy = this.baslangic.slice(5, 7)
        const firstYil = this.baslangic.slice(0, 4)
        const secondGun = this.bitis.slice(8, 10)
        const secondAy = this.bitis.slice(5, 7)
        const secondYil = this.bitis.slice(0, 4)
        return `${firstGun} ${store.getters.TURKISH_MONTH(firstAy)} ${firstYil}  -  ${secondGun} ${store.getters.TURKISH_MONTH(secondAy)} ${secondYil}  `
      }
      return ''
    },
    gelirListesi() {
      return Object.values(store.getters.KASA_GELIR_GET)
    },
    giderListesi() {
      return Object.values(store.getters.KASA_GIDER_GET)
    },
    ozetListesi() {
      return Object.values(store.getters.KASA_OZETI_LISTESI_GET)
    },
    kasa() {
      return store.getters.KASA_GET
    },
  },
  watch: {
    baslangic(newVal) {
      if (newVal && this.bitis) {
        this.loading(true)
        store.dispatch('kasaOzeti', {
          start: newVal,
          end: this.bitis,
        })
        store.dispatch('kasaListesi', {
          uzunluk: this.thirdPerPage,
          searchKey: this.thirdFilter,
          baslangic: 0,
          start: newVal,
          end: this.bitis,
        })
            .then(res => {
              this.loading(false)
              this.thirdToplamKayit = res.data.length
              this.thirdTotalRows = res.toplamKayit
            })
            .catch(() => this.loading(false))
        store.dispatch('kasaGelirListesi', {
          uzunluk: this.firstPerPage,
          searchKey: this.firstFilter,
          baslangic: 0,
          start: newVal,
          end: this.bitis,
        })
            .then(res => {
              this.firstTotalRows = res.toplamKayit
              this.firstToplamKayit = res.data.length
            })
        store.dispatch('kasaGiderListesi', {
          uzunluk: this.secondPerPage,
          searchKey: this.secondFilter,
          baslangic: 0,
          start: newVal,
          end: this.bitis,
        })
            .then(res => {
              this.secondTotalRows = res.toplamKayit
              this.secondToplamKayit = res.data.length
            })
      }
    },
    bitis(newVal) {
      if (newVal && this.baslangic) {
        this.loading(true)
        store.dispatch('kasaOzeti', {
          start: this.baslangic,
          end: newVal,
        })
        store.dispatch('kasaListesi', {
          uzunluk: this.thirdPerPage,
          searchKey: this.thirdFilter,
          baslangic: 0,
          start: this.baslangic,
          end: newVal,
        })
            .then(res => {
              this.loading(false)
              this.thirdToplamKayit = res.data.length
              this.thirdTotalRows = res.toplamKayit
            })
            .catch(() => this.loading(false))
        store.dispatch('kasaGelirListesi', {
          uzunluk: this.firstPerPage,
          searchKey: this.firstFilter,
          baslangic: 0,
          start: this.baslangic,
          end: newVal,
        })
            .then(res => {
              this.firstTotalRows = res.toplamKayit
              this.firstToplamKayit = res.data.length
            })
        store.dispatch('kasaGiderListesi', {
          uzunluk: this.secondPerPage,
          searchKey: this.secondFilter,
          baslangic: 0,
          start: this.baslangic,
          end: newVal,
        })
            .then(res => {
              this.secondTotalRows = res.toplamKayit
              this.secondToplamKayit = res.data.length
            })
      }
    },
    thirdFilter(newVal) {
      this.$refs['fourth-card'].showLoading = true
      this.$store
          .dispatch('kasaListesi', {
            uzunluk: this.secondPerPage,
            baslangic: 0,
            searchKey: newVal,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.$refs['fourth-card'].showLoading = false
            this.thirdTotalRows = res.toplamKayit
            this.thirdToplamKayit = res.data?.length
          })
      if (!newVal) {
        this.secondCurrentPage = 1
      }
    },
    secondFilter(newVal) {
      this.$refs['fourth-card'].showLoading = true
      this.$store
          .dispatch('kasaGiderListesi', {
            uzunluk: this.secondPerPage,
            baslangic: 0,
            searchKey: newVal,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.$refs['fourth-card'].showLoading = false
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.data?.length
          })
      if (!newVal) {
        this.secondCurrentPage = 1
      }
    },
    firstFilter(newVal) {
      this.$refs['fourth-card'].showLoading = true
      this.$store
          .dispatch('kasaGelirListesi', {
            uzunluk: this.firstPerPage,
            baslangic: 0,
            searchKey: newVal,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.$refs['fourth-card'].showLoading = false
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.data?.length
          })
      if (!newVal) {
        this.firstCurrentPage = 1
      }
    },
  },
  methods: {
    thirdPerPageChange(newVal) {
      const veri = this.thirdCurrentPage * newVal - newVal
      this.$refs['fourth-card'].showLoading = true
      this.$store
          .dispatch('kasaListesi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.thirdFilter,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.$refs['fourth-card'].showLoading = false
            this.thirdToplamKayit = res.data.length
            this.thirdTotalRows = res.toplamKayit
          })
    },
    thirdPageChange(newVal) {
      const veri = newVal * this.thirdPerPage - this.thirdPerPage
      this.$refs['fourth-card'].showLoading = true
      this.$store
          .dispatch('kasaListesi', {
            uzunluk: this.thirdPerPage,
            baslangic: veri,
            searchKey: this.thirdFilter,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.$refs['fourth-card'].showLoading = false
            this.thirdToplamKayit = res.data.length
            this.thirdTotalRows = res.toplamKayit
          })
    },
    secondPerPageChange(newVal) {
      const veri = this.secondCurrentPage * newVal - newVal
      this.$refs['fourth-card'].showLoading = true
      this.$store
          .dispatch('kasaGiderListesi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.secondFilter,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.$refs['fourth-card'].showLoading = false
            this.secondToplamKayit = res.data.length
            this.secondTotalRows = res.toplamKayit
          })
    },
    secondPageChange(newVal) {
      const veri = newVal * this.secondPerPage - this.secondPerPage
      this.$refs['fourth-card'].showLoading = true
      this.$store
          .dispatch('kasaGiderListesi', {
            uzunluk: this.secondPerPage,
            baslangic: veri,
            searchKey: this.secondFilter,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.$refs['fourth-card'].showLoading = false
            this.secondToplamKayit = res.data.length
            this.secondTotalRows = res.toplamKayit
          })
    },
    firstPerPageChange(newVal) {
      const veri = this.firstCurrentPage * newVal - newVal
      this.$refs['fourth-card'].showLoading = true
      this.$store
          .dispatch('kasaGelirListesi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.firstFilter,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.$refs['fourth-card'].showLoading = false
            this.firstToplamKayit = res.data.length
            this.firstTotalRows = res.toplamKayit
          })
    },
    firstPageChange(newVal) {
      const veri = newVal * this.firstPerPage - this.firstPerPage
      this.$refs['fourth-card'].showLoading = true
      this.$store
          .dispatch('kasaGelirListesi', {
            uzunluk: this.firstPerPage,
            baslangic: veri,
            searchKey: this.firstFilter,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.$refs['fourth-card'].showLoading = false
            this.firstToplamKayit = res.data.length
            this.firstTotalRows = res.toplamKayit
          })
    },
    gelirListesiGet() {
      store.dispatch('kasaGelirListesi', {
        uzunluk: this.firstPerPage,
        searchKey: this.firstFilter,
        baslangic: 0,
        start: this.baslangic,
        end: this.bitis,
      })
          .then(res => {
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.data.length
          })
    },
    giderListesiGet() {
      store.dispatch('kasaGiderListesi', {
        uzunluk: this.secondPerPage,
        searchKey: this.secondFilter,
        baslangic: 0,
        start: this.baslangic,
        end: this.bitis,
      })
          .then(res => {
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.data.length
          })
    },
    devirGirisi() {
      this.devirKayitBtn = true
      setTimeout(() => {
        this.devirKayitBtn = false
      }, 1000)
      this.loading(true)
      store.dispatch('kasaGirisi', {
        girisTuru: this.devir.tur.key,
        not: this.devir.not,
        tutar: this.devir.tutar,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.gelirListesiGet()
              this.giderListesiGet()
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
              this.$bvModal.hide('kasa-devir')
              store.dispatch('yillikRapor')
                  .then(res => {
                    const data = { ...res }
                    delete data.userToken
                    const seriesData = Object.values(data)
                        .map(item => ({
                          giderKayitlari: item.giderKayitlari,
                          toplamTahsilat: item.toplamTahsilat,
                          toplamOdeme: item.toplamOdeme,
                        }))
                    const giderKayitlariData = seriesData.map(item => item.giderKayitlari)
                    const toplamTahsilatData = seriesData.map(item => item.toplamTahsilat)
                    const toplamOdemeData = seriesData.map(item => item.toplamOdeme)
                    this.series = [
                      {
                        name: 'Tahsilat',
                        data: toplamTahsilatData,
                      },
                      {
                        name: 'Ödeme',
                        data: toplamOdemeData,
                      },
                      {
                        name: 'Toplam Gider',
                        data: giderKayitlariData,
                      },
                    ]
                  })
              store.dispatch('kasaOzeti', {
                start: '',
                end: '',
              })
              store.dispatch('kasaListesi', {
                uzunluk: this.thirdPerPage,
                searchKey: this.thirdFilter,
                baslangic: 0,
                start: this.baslangic,
                end: this.bitis,
              })
                  .then(res => {
                    this.thirdToplamKayit = res.data.length
                    this.thirdTotalRows = res.toplamKayit
                  })
              this.loading(false)
              this.devir.tur = {
                key: 1,
                text: 'Kasa Girişi',
              }
              this.devir.not = ''
              this.devir.tutar = ''
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            this.loading(false)
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    devirSil(id) {
      store.dispatch('kasaSil', id)
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.gelirListesiGet()
              this.giderListesiGet()
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
              store.dispatch('yillikRapor')
                  .then(res => {
                    const data = { ...res }
                    delete data.userToken
                    const seriesData = Object.values(data)
                        .map(item => ({
                          giderKayitlari: item.giderKayitlari,
                          toplamTahsilat: item.toplamTahsilat,
                          toplamOdeme: item.toplamOdeme,
                        }))
                    const giderKayitlariData = seriesData.map(item => item.giderKayitlari)
                    const toplamTahsilatData = seriesData.map(item => item.toplamTahsilat)
                    const toplamOdemeData = seriesData.map(item => item.toplamOdeme)
                    this.series = [
                      {
                        name: 'Tahsilat',
                        data: toplamTahsilatData,
                      },
                      {
                        name: 'Ödeme',
                        data: toplamOdemeData,
                      },
                      {
                        name: 'Toplam Gider',
                        data: giderKayitlariData,
                      },
                    ]
                  })
              store.dispatch('kasaOzeti', {
                start: '',
                end: '',
              })
              store.dispatch('kasaListesi', {
                uzunluk: this.thirdPerPage,
                searchKey: this.thirdFilter,
                baslangic: 0,
                start: this.baslangic,
                end: this.bitis,
              })
                  .then(res => {
                    this.thirdToplamKayit = res.data.length
                    this.thirdTotalRows = res.toplamKayit
                  })
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Hata!',
                        text: mesaj,
                        icon: 'ThumbsDownIcon',
                        variant: 'danger',
                      },
                    },
                )
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    devirReset() {
      this.$bvModal.hide('kasa-devir')
      const t = this
      t.devir.tur = {
        key: 1,
        text: 'Kasa Girişi',
      }
      t.devir.not = ''
      t.devir.tutar = ''
    },
    print() {
      window.print()
    },
    loading(islem) {
      if (islem) {
        this.$refs['first-card'].showLoading = true
        this.$refs['second-card'].showLoading = true
        this.$refs['third-card'].showLoading = true
        this.$refs['fourth-card'].showLoading = true
      } else {
        this.$refs['first-card'].showLoading = false
        this.$refs['second-card'].showLoading = false
        this.$refs['third-card'].showLoading = false
        this.$refs['fourth-card'].showLoading = false
      }
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'list'].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    reset() {
      this.baslangic = ''
      this.bitis = ''
      store.dispatch('kasaGelirListesi', {
        uzunluk: this.firstPerPage,
        searchKey: this.firstFilter,
        baslangic: 0,
      })
          .then(res => {
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.data.length
          })
    },
  },
  created() {
    store.dispatch('yillikRapor')
        .then(res => {
          const data = { ...res }
          delete data.userToken
          const seriesData = Object.values(data)
              .map(item => ({
                giderKayitlari: item.giderKayitlari,
                toplamTahsilat: item.toplamTahsilat,
                toplamOdeme: item.toplamOdeme,
              }))
          const giderKayitlariData = seriesData.map(item => item.giderKayitlari)
          const toplamTahsilatData = seriesData.map(item => item.toplamTahsilat)
          const toplamOdemeData = seriesData.map(item => item.toplamOdeme)
          this.series = [
            {
              name: 'Tahsilat',
              data: toplamTahsilatData,
            },
            {
              name: 'Ödeme',
              data: toplamOdemeData,
            },
            {
              name: 'Toplam Gider',
              data: giderKayitlariData,
            },
          ]
        })
    store.dispatch('kasaOzeti', {
      start: '',
      end: '',
    })
    store.dispatch('kasaListesi', {
      uzunluk: this.thirdPerPage,
      searchKey: this.thirdFilter,
      baslangic: 0,
      start: this.baslangic,
      end: this.bitis,
    })
        .then(res => {
          this.thirdToplamKayit = res.data.length
          this.thirdTotalRows = res.toplamKayit
        })
  },
  beforeDestroy() {
    store.commit('KASA_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#kasa-header .card-header {
  display: none !important;
}

.kasa-table .table thead th, table tbody td {
  padding: 0.2rem !important;
}
</style>
<style scoped>
.pull-up {
  transition: 150ms;
}

.search-bar {
  width: 30% !important;
}

@media (max-width: 1231px) {
  .search-bar {
    width: 60% !important;
  }
}

@media (max-width: 374px) {
  .search-bar {
    width: 100% !important;
  }
}

@media (max-width: 1615px) {
  .input-group /deep/ input {
    width: 100px !important;
  }
}

@media (max-width: 1565px) {
  .date-border-color {
    display: none !important;
  }
}

@media (min-width: 1615px) {
  .input-group /deep/ input {
    width: 140px !important;
  }
}

.date-border-color {
  border-color: #bdb5d7 !important
}

.finans {
  white-space: nowrap !important;
}

.search-input {
  border-color: #c3baba !important;
}

.input-group {
  box-shadow: none !important;
}

.tarih {
  transform: translateY(30%);
}

.per-page-input {
  width: 35%;
}

@media (max-width: 1600px) {
  .per-page-input {
    width: 50% !important;
  }
}

@media (max-width: 1100px) {
  .per-page-input {
    width: 80% !important;
  }
}

@media (max-width: 500px) {
  .per-page-input {
    width: 100% !important;
  }
}

</style>
